/* Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background: #B6F9E1;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Lato';
  overflow-x: hidden;
  background-color: #fdfdfd;
}

h1 {
  font-size: 4rem;
  font-weight: normal;
  margin-bottom: 3rem;
}

#multi-head {
  padding: 0 2rem;
}

/* Dialog */

#dialog-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  animation: fadeIn 0.3s backwards;
  backdrop-filter: blur(3px);
}

#dialog-content {
  padding: 5rem 7rem;
  border-radius: 2rem;
  box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  width: 690px;
  text-align: center;
  line-height: 23px;
  animation: fadeIn 0.3s backwards;
}

#dialog-close {
  all: unset;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  transition: 0.2s transform ease-out;
}

#dialog-close:focus,
#dialog-close:focus-visible {
  outline: 1px solid #000;
}

#dialog-close:hover {
  transform: scale(1.1);
}

#dialog-content h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 4rem;
}

#dialog-content p {
  font-size: 1.8rem;
}

#dialog-actions {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 3rem;

  border-top: 2px solid #fafafa;
  border-radius: 2px;
  padding-top: 2rem;
}

#dialog-actions > button:focus,
#dialog-actions > button:focus-within,
#dialog-actions > button:focus-visible {
  border-radius: 5rem;
  outline: 1px solid #000;
}

button {
  background: none;
  border: none;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
td,
th {
  font-weight: 400;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1600px) {
  h1 {
    font-size: 3rem;
  }
}