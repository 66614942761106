.drawerContainer {
  display: flex;
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: calc(100vh - 4rem);
  border-radius: 2rem;
  background-color: #fff;
  box-shadow: 0 2px 0.5rem rgba(109, 244, 195, 0.5), 0 0 2rem rgb(255, 255, 255) !important;
  z-index: 100;
  border: 1px solid rgba(109, 244, 195, 0.5);

  svg {
    min-width: fit-content;
  }

  > div {
    height: 100%;
  }

.condenseMenu {
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 2.5rem 2.4rem;

  svg {
    font-size: 1.8rem;
    transition: 0.3s transform ease;
    transform: rotateZ(0);
  }

  span {
    padding-left: 1rem;
    white-space: nowrap;
    opacity: 0;
    transition: 0.3s opacity ease;
    font-size: 1.2rem;
    font-style: italic;
    pointer-events: none;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  width: 6.5rem;
  transition: 0.2s width ease-in, 0.5s border-radius ease-out;
  will-change: width, border-radius;

  &.active {
    width: 22rem;
    
    .condenseMenu svg {
      transform: rotateZ(180deg);
    }

    .condenseMenu span {
      opacity: 1;
    }

    .menuContainer .itemName span {
      opacity: 1;
    }

    .configuration {
      transform: translateY(-2rem);
    }

    .copyright {
      opacity: 1;
    }

    .tooltip {
      opacity: 0 !important;
    }

    > ul li {
      border-radius: 2rem;
    }

    .menuIcon {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1600px) {
    top: 6rem;
    height: calc(100vh - 8rem);
  }
}
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(-2rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.tempSubMenu {
  position: absolute;
  left: 88%;
  border-radius: 2rem;
  min-width: 25rem;
  padding: 3rem;
  animation: fadeLeft 0.2s ease-in-out;
  transition: all 0.3s ease;
  background-color: #fff;
  box-shadow: 0 2px 0.5rem rgba(109, 244, 195, 0.5), 0 0 2rem rgb(255, 255, 255) !important;
  border: 1px solid rgba(109, 244, 195, 0.5);

  > h2 {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  > ul {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    
    list-style: none;
    
    > li {
      font-size: 1.6rem;

      a {
        text-decoration: none;
        color: #252525;
        display: inline-block;
        transition: 0.3s color ease;
        
        div {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        svg {
          font-size: 1.8rem;
        }

        span {
          display: flex;
        }

        &::after {
          content: '';
          margin-top: 0.5rem;
          display: block;
          width: 0;
          height: 2px;
          background-color: rgba(92, 215, 170, 0.886);
          transition: 0.2s width ease-out;
        }
  
        &:hover::after {
          width: 100%;
        }
  
        &.active::after {
          width: 100%;
        }
  
        &.active {
          font-weight: 500;
        }
  
        &:hover {
          font-weight: 500;
          color: rgba(92, 215, 170, 0.886);
        }
      }
    }
  }
  
}


.menuContainer {
  padding: 0 1rem;
  
  > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 0.5rem;

    > li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      cursor: pointer;
      border-radius: 3rem;
      position: relative;
      min-height: 4.5rem;
      min-width: 4.5rem;
      justify-content: center;

      .itemHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .itemName {
          display: flex;
          gap: 1rem;
          align-items: center;
          flex: 1;
          text-decoration: none;
          color: #000;
          padding: 1.3rem;
    
          span {
            white-space: nowrap;
            font-size: 1.5rem;
            transition: 0.3s opacity ease;
            opacity: 0;
            pointer-events: none;
            will-change: opacity;
            position: absolute;
            transform: translateX(3rem);
          }
        }
      }

      &:hover, &.active {
        background-color: #B6F9E1;
        box-shadow: 0 2px 0.2rem rgba(149, 157, 157, 0.085);
      }

      &:hover {
        .tooltip {
          opacity: 1;
        }
      }

      svg {
        font-size: 2rem;
      }

      .tooltip {
        position: absolute;
        left: 7rem;
        z-index: 1;
        box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.2);
        border-radius: 10rem;
        overflow: hidden;
        background-color: #fff;
        transition: 0.1s opacity ease;
        opacity: 0;
        pointer-events: none;
        will-change: opacity;

        p {
          font-size: 1.3rem;
          padding: 0.7rem 1.7rem;
        }
      }
    }
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  justify-content: flex-end;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  white-space: nowrap;
  transition: 0.2s opacity ease;
  opacity: 0;
}

.configuration {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  margin-top: 3rem;
  transition: 0.3s transform ease;
  will-change: transform;
}

.submenu {
  list-style: none;
  width: 100%;
  margin-top: -1.5rem;

  a {
    text-decoration: none;
    flex: 1;
    padding: 1.3rem;
  }
  
  li {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    border-radius: 2rem;
    padding-left: 0.5rem;
    position: relative;
    transition: 0.2 background-color ease;

    &:hover {
      width: 100%;
      background-color: #e7e7e7;
    }
  }

  span {
    font-size: 1.4rem;
    margin-left: 1rem;
    color: #000;
  }
}

.menuIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s opacity ease-in, 0.1s background-color ease-in,  0.1s ease-out transform;
  will-change: opacity;
  opacity: 0;

  &:hover {
    background-color: #dadada;
  }

  &.active {
    background-color: #dadada !important;
  }
}

.openedSubmenu {
  border-radius: 2rem !important;
}