.cardContainer {
  width: 100%;
  border-radius: 1rem;
  // box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 0.2rem rgba(0, 0, 0, 0.2), 0px 2px 0.5rem rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 2rem;

  &.radius {
    &-small {
      border-radius: 0.25rem;
    }

    &-none {
      border-radius: 0;
    }
  }

  &.elevation {
    &-none {
      box-shadow: none;
    }
  }

  &.padding-none {
    padding: 0;
  }

  &.display {
    &-flex {
      display: flex;
    }
  }

  &.justify {
    &-center {
      justify-content: center;
    }

    &-space-between {
      justify-content: space-between;
    }

    &-space-around {
      justify-content: space-around;
    }
    &-end {
      justify-content: flex-end;
    }
  }

  &.align {
    &-start {
      align-items: start;
    }
    
    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }

    &-end {
      align-items: flex-end;
    }
  }

  &.position {
    &-relative {
      position: relative;
    }
    
    &-absolute {
      position: absolute;
    }
  }
}