.input {
  border: none;
  outline: none;
  box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 5rem;
  padding: 1rem 2rem;

  &[type=checkbox] {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  &:focus:not([type=checkbox]) {
    outline: 2px solid #000;
  }

  &:hover:not(:focus, :disabled, [type=checkbox]) {
    outline: 2px solid rgb(109, 109, 109);
  }
}

.label {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.money {
  position: relative;

  &::after {
    content: 'R$';
    position: absolute;
    top: 0;
  }
}