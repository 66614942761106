.layoutContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2rem 3rem 0 11rem;
  padding-bottom: 1rem;
  height: initial !important;

  > * {
    animation: fadeIn 0.3s ease-in;
  }

  > h1 {
    font-size: 4rem;
    font-weight: normal;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}