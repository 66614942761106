.buttonContainer {
  padding: 1rem 2rem;
  border-radius: 5rem;
  font-size: 1.6rem;
  box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s opacity ease-out, 0.3s box-shadow ease-out;
  display: flex;
  align-items: center;
  gap: 1rem;
  outline: 0;
  border: 0;
  height: 45px;
  font-family: 'Lato';
  transition: 0.6s background ease, 0.3s opacity ease, 0.3s box-shadow ease, 0.6s color;
  position: relative;

  svg {
    font-size: 2rem;
  }

  &.size {
    &-small {
      height: 35px;
    }
  }

  &.status {
    &-default {
      background-color: #fff;
      color: #000;
    }

    &-active {
      background-color: #000;
      color: #fff;
    }
    
    &-success {
      background-color: #58bf4be6;
      color: #fff;
    }

    &-info {
      background-color: #2856d6e5;
      color: #fff;
    }
    
    &-danger {
      background-color: #d63c28e5;
      color: #fff;
    }
  }

  &.align {
    &-center {
      justify-content: center;
    }
  }

  &.elevation {
    &-none {
      box-shadow: none;
    }
  }

  &:hover, &:focus {
    opacity: 1;
    box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: 2px solid #000;
  }

  @media screen and (max-width: 1600px) {
    height: 4rem;
  }
}

.isLoading {
  background-color: #f5f5f5 !important;
  opacity: 1;
  box-shadow: 0px 2px 0.5rem rgba(0, 0, 0, 0.3);
}

.disabled {
  background-color: #ededed !important;
  box-shadow: none;
  color: #363636 !important;
  opacity: 0.6;
  cursor: auto !important;

  &:hover {
    box-shadow: none;
    opacity: 0.6;
  }
}

.loading {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
}

.loadingDot {
  height: 0.7rem;
  width: 0.7rem;
  background-color: #000;
  border-radius: 50%;

  &:nth-child(1) {
    animation: loadingDot 1s ease-in-out infinite;
  }
  &:nth-child(2) {
    animation: loadingDot 1s ease-in-out 0.5s infinite;
  }
  &:nth-child(3) {
    animation: loadingDot 1s ease-in-out 1s infinite;
  }
}

@keyframes loadingDot {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
