$blur: 0.7;

.container {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 255, 195, 0.452), #fff, rgba(109, 244, 195, 0.166), rgba(104, 216, 224, 0.252), #fff, rgba(19, 230, 245, 0.338));
  backdrop-filter: blur(20px);
  background-size: 300% 300%;
  animation: backgroundMoving 12s ease-in-out infinite;
}

.welcome {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1221212;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255, $blur) 0%, rgba(254,254,254, 1) 30%, rgba(231,231,231, 0.9) 60%, rgba(177,177,177, 1) 100%);
  position: absolute;
  transition: 1s background ease;
  animation: fadeIn 1s ease forwards, hide 0.3s ease 2s forwards, hidden 0.3s ease 2s forwards;

  div {
    font-weight: 600;
    text-align: center;
    animation: fadeInMove 3s ease-out forwards;
    transition: transform 0.3s ease, opacity 3s ease;
    margin-bottom: 5rem;

    h2 {
      font-size: 7rem;
      text-transform: uppercase;
    }

    h3 {
      font-size: 3rem;

      span {
        font-weight: bold;
      }
    }
  }
}

.loginCard {
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  animation: show 0.8s ease-out 2s forwards, borderMoving 12s ease infinite alternate;
  transition: transform 0.5s ease-out;
  transform: translateX(-50%) translateY(-40%);
  opacity: 0;
  will-change: transform, opacity;
  height: 500px;
  border-radius: 3rem;
  box-shadow: 0 2px 1rem rgba(109, 244, 195, 0.633), 0 0 3rem rgb(255, 255, 255) !important;
  backdrop-filter: blur(20px);
  background-color: #ffffff;

  h1 {
    margin-top: 2rem;
  }

  h2 {
    margin-bottom: 2rem;
    color: #6e6e6e;
    text-align: center;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;

  input {
    width: 350px;
    height: 45px;
    font-size: 1.5rem;
    font-family: Lato;
  }

  button {
    width: 150px;
    margin: 1rem auto 0;

    * {
      transition: all 1s ease;
      animation: fadeIn 1s ease forwards;
    }

    svg {
      transition: 1s opacity ease;
    }
  }
}

.successLogin {
  opacity: 1;

  svg {
    animation: fadeIn 1s ease forwards;
  }
}

.toastBody {
  font-size: 1.5rem;
}

.loginCardBye {
  opacity: 1;
  transform: translateX(-50%) translateY(-60%);
  
  animation: bye 1s ease-out forwards 1s !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hidden {
  0% {
    position: absolute;
  }
  100% {
    position: static;
  }
}

@keyframes backgroundMoving {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes fadeInMove {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  70% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: trwanslateX(-50%) translateY(-40%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-60%);
  }
}

@keyframes borderMoving {
  0% {
    border-radius: 3rem; 
  }
  
  20% {
    border-radius: 2rem;
  }

  50% {
    border-radius: 4rem;
  }

  70% {
    border-radius: 6rem;
  }

  100% {
    border-radius: 5rem;
  }
}

@keyframes bye {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(-60%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-40%);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    pointer-events: none;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}